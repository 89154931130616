import { Dispatch, SetStateAction, useCallback } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const DepartmentsReportFilters = ({ fetchOptionsSetter }: Props) => {
  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        page: 0,
        dateFromTs: startDate ?? undefined,
        dateToTs: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  return <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />;
};

export default DepartmentsReportFilters;
