import { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import Slider from '../../components/common/Slider';

import styles from './WorkZone.module.scss';
import { ISlide } from '../../types/ISlide';
import DraggableView from '../../components/common/DraggableView';
import { layoutSelectors } from '../../redux/slices/layout';
import { SLIDE_PREVIEW_SIZE } from '../../const';

interface Props {
  className?: string;
}

const InsertZone = ({ className }: Props) => {
  const dispatch = useAppDispatch();

  const insertedImages = useAppSelector(operatorTaskSelectors.insertedImages.selectAll);
  const activeInsertedImageId = useAppSelector(operatorTaskSelectors.selectInsertedActiveImageId);
  const insertedSelection = useAppSelector(operatorTaskSelectors.selectInsertedSelection);
  const image = useAppSelector(operatorTaskSelectors.selectInsertedActiveImage);
  const width = useAppSelector(layoutSelectors.selectPageWidth);

  const insertedSlideClickHandler = useCallback(
    (image: ISlide) => {
      dispatch(operatorTaskActions.setInsertedActiveImage(image.id));
    },
    [dispatch],
  );

  const slides: ISlide[] = useMemo(() => {
    const items: ISlide[] = insertedImages.map(img => {
      return {
        id: img.id,
        src: img.filename + `/${SLIDE_PREVIEW_SIZE}/${SLIDE_PREVIEW_SIZE}`,
        mod: img.mask,
      };
    });
    return items;
  }, [insertedImages]);

  return (
    <div className={classNames(styles.root, styles.rigth, className)}>
      <DraggableView
        src={image ? `${image.filename}.png` : undefined}
        imageWidth={width}
        imageFormat={image?.format}
        className={styles.view}
        scrollBy={{ left: 120 }}
        isVisible={true}
      />
      <Slider
        headerText={`Всего: ${slides.length}`}
        docId={null}
        items={slides}
        activeId={activeInsertedImageId ?? undefined}
        onSlideClick={insertedSlideClickHandler}
        className={styles.listSlider}
        onSlideSelect={img => dispatch(operatorTaskActions.changeInsertedSelection(img.id))}
        selection={insertedSelection}
      />
    </div>
  );
};

export default InsertZone;
