import TextInput from '../../../../components/ui/TextInput';
import { SearchIcon } from '../../../../components/ui/icons/Icons';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const PetitionsReportFilters = ({ fetchOptionsSetter }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const updateFilter = useCallback(
    (value: string, filterId: string) => {
      fetchOptionsSetter(prev => ({ ...prev, [`${filterId}`]: value.length ? value : undefined }));
    },
    [fetchOptionsSetter],
  );

  useEffect(() => {
    const timeOut = setTimeout(() => updateFilter(searchTerm, 'search'), 300);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchTerm, updateFilter]);

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        dateFromTs: startDate ?? undefined,
        dateToTs: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  return (
    <>
      <TextInput
        type="search"
        className="mr-4 w-30"
        placeholder="Введите штрихкод"
        buttonIcon={<SearchIcon />}
        value={searchTerm}
        onChange={setSearchTerm}
      />
      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
    </>
  );
};

export default PetitionsReportFilters;
