import { Enum } from '../data/enum';
import { TId } from './TId';

export interface IComplexInfo {
  id: TId;
  parentId?: TId;
  partNumber?: number;
  partsTotal?: number;
  name: string | null;
  type: ComplexType;
  state: ComplexState;
  barcode: string;
  userId: number;
  userShowName: string;
  pagesTotal: number;
  awardsTotal: number;
  awardsFactCount: number;
  pagesProcessed: number;
  recognizedPages: number;
  importantPages: number;
  dateAddTs: string;
  dateUpdTs: string;
  firstPageFilename: string;
  errorMessage: string | null;
  priority?: ComplexPriority;
  copiedToSed: null | boolean;
}

export enum ComplexState {
  LoadingInProcess = 'loading__in_process',
  LoadingError = 'loading__error',
  ValidationReadyTo = 'validation__ready_to',
  ValidationInProcess = 'validation__in_process',
  ValidationError = 'validation__error',
  ValidationErrorParity = 'validation__error_parity',
  ValidationErrorDpi = 'validation__error_dpi',
  ValidationErrorPageFormat = 'validation__error_page_format',
  PrepareReadyTo = 'prepare__ready_to',
  PrepareInProcess = 'prepare__in_process',
  PrepareError = 'prepare__error',
  RecognitionReadyTo = 'recognition__ready_to',
  RecognitionInProcess = 'recognition__in_process',
  RecognitionError = 'recognition__error',
  BuildStructureReadyTo = 'build_structure__ready_to',
  BuildStructureInProcess = 'build_structure__in_process',
  BuildStructureError = 'build_structure__error',
  ReadyToWorkNoCode = 'ready_to_work__no_code',
  ReadyToWorkDuplicateCode = 'ready_to_work__duplicate_code',
  ReadyToWork = 'ready_to_work',
  InWork = 'in_work',
  AssembleReadyTo = 'assemble__ready_to',
  AssembleInProcess = 'assemble__in_process',
  AssembleError = 'assemble__error',
  SendReadyTo = 'send__ready_to',
  SendInProcess = 'send__in_process',
  SendError = 'send__error',
  Sent = 'sent',
  SentError = 'sent__error',
  SentSuccess = 'sent__success',
  PartReady = 'part_ready',
  PartComplete = 'part_complete',
  WaitForPart = 'wait_for_part',
  PartsReady = 'parts_ready',
  Deleted = 'deleted',
  LoadingDuplicate = 'loading__duplicate',
  LoadingErrorDuplicate = 'loading__error_duplicate',
}

export const READY_STATES: ComplexState[] = [
  ComplexState.ReadyToWorkDuplicateCode,
  ComplexState.ReadyToWorkNoCode,
  ComplexState.ReadyToWork,
  ComplexState.InWork,
];

export const ComplexStateEnum = new Enum<ComplexState>([
  [ComplexState.LoadingInProcess, 'Копирование'],
  [ComplexState.LoadingError, 'Ошибка копирования'],
  [ComplexState.ValidationReadyTo, 'Проверка задания'],
  [ComplexState.ValidationInProcess, 'Проверка задания'],
  [ComplexState.ValidationError, 'Ошибка проверки'],
  [ComplexState.ValidationErrorParity, 'Ошибка четности'],
  [ComplexState.ValidationErrorDpi, 'Ошибка dpi'],
  [ComplexState.ValidationErrorPageFormat, 'Неподходящий формат'],
  [ComplexState.PrepareReadyTo, 'Определение формата'],
  [ComplexState.PrepareInProcess, 'Резка А3'],
  [ComplexState.PrepareError, 'Ошибка резки'],
  [ComplexState.RecognitionReadyTo, 'Готов к распознаванию'],
  [ComplexState.RecognitionInProcess, 'На распознавании'],
  [ComplexState.RecognitionError, 'Ошибка распознавания'],
  [ComplexState.BuildStructureReadyTo, 'Ожидание построения структуры'],
  [ComplexState.BuildStructureInProcess, 'Построение структуры'],
  [ComplexState.BuildStructureError, 'Ошибка построения структуры'],
  [ComplexState.ReadyToWorkNoCode, 'Готов к проверке'],
  [ComplexState.ReadyToWorkDuplicateCode, 'Готов к проверке'],
  [ComplexState.ReadyToWork, 'Готов к проверке'],
  [ComplexState.InWork, 'Обработка оператором'],
  [ComplexState.AssembleReadyTo, 'Сборка'],
  [ComplexState.AssembleInProcess, 'Сборка'],
  [ComplexState.AssembleError, 'Ошибка сборки'],
  [ComplexState.SendReadyTo, 'Готов к отправке'],
  [ComplexState.SendInProcess, 'Отправка в Награды'],
  [ComplexState.SendError, 'Ошибка отправки в Награды'],
  [ComplexState.Sent, 'Отправлено в Награды'],
  [ComplexState.SentError, 'Не принято'],
  [ComplexState.SentSuccess, 'Принято в Награды'],
  [ComplexState.PartReady, 'Часть готова'],
  [ComplexState.PartComplete, 'Часть завершена'],
  [ComplexState.WaitForPart, 'Ожидание частей'],
  [ComplexState.PartsReady, 'Готов к сборке'],
  [ComplexState.Deleted, 'Удалено'],
  [ComplexState.LoadingDuplicate, 'Загрузка - дубликат'],
  [ComplexState.LoadingErrorDuplicate, 'Ошибка загрузки (дубликат)'],
]);

export enum ComplexType {
  Award = 'award',
  AwardBig = 'awardbig',
  AwardBigPart = 'awardbigpart',
}

export const ComplexTypeEnum = new Enum<ComplexType>([
  [ComplexType.Award, 'Наградной'],
  [ComplexType.AwardBig, 'Большой наградной'],
  [ComplexType.AwardBigPart, 'Часть'],
]);

export const ComplexProcessingEnum = new Enum<ComplexType>([
  [ComplexType.Award, 'Целиком'],
  [ComplexType.AwardBigPart, 'Частями'],
]);

export enum ComplexPriority {
  High = 1,
  Raise = 2,
  Default = 3,
}

export const ComplexPriorityEnum = new Enum<ComplexPriority>([
  [ComplexPriority.High, 'Высокий'],
  [ComplexPriority.Raise, 'Повышенный'],
  [ComplexPriority.Default, 'Обычный'],
]);
