import { useState } from 'react';
import GridPage from '../../gridPage/GridPage';
import UsersReportActions from './UsersReportActions';
import UsersReportFilters from './UsersReportFilters';
import UsersReportGrid from './UsersReportGrid';
import { IStatOptions } from '../../../../redux/api/statistic';

const UsersReport = () => {
  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  return (
    <GridPage
      title="Статистика по сотрудникам"
      className="pt-2 pb-7"
      panel={<UsersReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={<UsersReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />}
      actions={<UsersReportActions fetchOptions={fetchOptions} />}
    />
  );
};
export default UsersReport;
