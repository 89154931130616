import { useEffect, useMemo, useState } from 'react';
import GridPage from '../gridPage/GridPage';
import HistoryFilters from './HistoryFilters';
import HistoryGrid from './HistoryGrid';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../redux/hooks';
import { userSelectors } from '../../../redux/slices/user';
import { Role } from '../../../types/IUserInfo';
import ErrorPage from '../../ErrorPage';
import HistoryActions from './HistoryActions';
import { IEventsOptions } from '../../../redux/api/events';
import { QueryKeys } from '../../../data/QueryKeys';
import { ObjectType } from '../../../types/IEvent';

const HistoryPage = () => {
  const { section, taskId } = useParams();
  const isTaskHistory = !!section && !!taskId;

  // console.log(section, taskId);

  const user = useAppSelector(userSelectors.selectUserData);

  if (user?.role === Role.Operator && (!section || !taskId))
    return <ErrorPage text="Недостаточно прав для просмотра страницы" />;

  const objectType = useMemo(() => {
    if (section === QueryKeys.Operator) return ObjectType.Complex;
    if (section === QueryKeys.Printing) return ObjectType.PrintJob;
    if (!section) return `${ObjectType.Complex},${ObjectType.PrintJob}`;
    return undefined;
  }, [section]);

  const [fetchOptions, setFetchOptions] = useState<IEventsOptions>({
    page: 0,
    perPage: 20,
    objectId: taskId ?? undefined,
    objectType,
  });

  useEffect(() => {
    setFetchOptions(prev => ({
      ...prev,
      objectId: taskId ?? undefined,
      objectType,
    }));
  }, [objectType, section, taskId]);

  return (
    <GridPage
      title="История обработки"
      panel={<HistoryFilters isSingle={isTaskHistory} fetchOptionsSetter={setFetchOptions} />}
      grid={
        <HistoryGrid
          isSingle={isTaskHistory}
          fetchOptions={fetchOptions}
          fetchOptionsSetter={setFetchOptions}
        />
      }
      actions={<HistoryActions fetchOptions={fetchOptions} />}
    />
  );
};

export default HistoryPage;
