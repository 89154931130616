import { ReactNode, useState } from 'react';
import { Table } from '@tanstack/react-table';

import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import { SettingsIcon } from '../ui/icons/Icons';
import Card from '../ui/Card';
import PopperButton from '../ui/PopperButton';
import DateFilter from './DateFilter';

import styles from './GridSettings.module.scss';
import { useAppSelector } from '../../redux/hooks';
import { userSelectors } from '../../redux/slices/user';

interface Props<T> {
  table: Table<T>;
  columnSettings?: boolean;
  hideMyFilter?: boolean;
  hiddenFilterColumn?: string;
  dateFilterColumn?: string;
  extra?: ReactNode;
}

const GridSettings = <T,>({
  table,
  columnSettings,
  hideMyFilter,
  hiddenFilterColumn,
  dateFilterColumn,
  extra,
}: Props<T>) => {
  const [myFilter, setMyFilter] = useState(false);
  const userData = useAppSelector(userSelectors.selectUserData);

  const btn = (
    <Button viewType="small">
      <SettingsIcon />
    </Button>
  );

  const dropDown = (
    <Card className={styles.list}>
      {table
        .getAllColumns()
        .filter(
          col => col.id !== 'select' && col.id !== hiddenFilterColumn && col.id !== 'errorSign',
        )
        .map(column => {
          return (
            <Checkbox
              key={column.id}
              label={
                column.columnDef.header?.toString().length
                  ? column.columnDef.header.toString()
                  : column.id === 'errors'
                    ? 'Ошибки'
                    : column.id
              }
              checked={column.getIsVisible()}
              onChange={state =>
                column.getToggleVisibilityHandler()({
                  target: { checked: state },
                })
              }
            />
          );
        })}
    </Card>
  );

  const myFilterChangeHandler = (state: boolean) => {
    setMyFilter(state);
    if (state) {
      table.setColumnFilters(prev => [...prev, { id: 'userId', value: userData?.id }]);
    } else {
      table.setColumnFilters(prev => prev.filter(f => f.id !== 'userId'));
    }
  };

  return (
    <div className={styles.root}>
      {columnSettings && <PopperButton button={btn} dropDown={dropDown} />}
      {!hideMyFilter && (
        <Checkbox label="Только свои" checked={myFilter} onChange={myFilterChangeHandler} />
      )}
      {dateFilterColumn && (
        <DateFilter filterSetter={table.setColumnFilters} dateFilterColumn={dateFilterColumn} />
      )}
      {extra && <div className={styles.extra}>{extra}</div>}
    </div>
  );
};

export default GridSettings;
