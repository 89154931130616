import { DocSection } from '../../../types/DocSection';
import { TId } from '../../../types/TId';
import {
  INormalizedComplexDocument,
  INormalizedImage,
  IOperatorTaskSlice,
  actionsAdapter,
  awardsAdapter,
  imagesAdapter,
  insertedImagesAdapter,
} from './slice';
import {
  EditorActionType,
  IAction,
  IInsertImageAction,
  IMoveImageAction,
  IReplaceImageAction,
  IRotateAction,
  ISplitAwardAction,
  IStackAction,
  ISwitchImagesAction,
} from '../../../types/EditorAction';
import { nanoid } from '@reduxjs/toolkit';
import { IImage, ImageState } from '../../../types/IImage';
import { toast } from 'react-toastify';
import { IFilter } from '../../../types/IFilter';

export const getImageMask = (img: IImage | INormalizedImage): ImageState | undefined => {
  if ('deleted' in img && img.deleted) return ImageState.Deleted;
  if (img.inserted) return ImageState.Added;
  if (img.needRecognize) return ImageState.NotRecognized;
  if (!img.important) return ImageState.Empty;
  if (!img.recognized) return ImageState.NotRecognized;
  return undefined;
};

export const mapNormalizedImageNew = (
  docId: TId,
  fromPetition: boolean,
  img: IImage,
): INormalizedImage => ({
  ...img,
  docId,
  docType: fromPetition ? DocSection.Petitions : DocSection.Awards,
  deleted: false,
  touched: false,
  actions: [],
  mask: getImageMask(img),
});

export const getLinkedImages = (state: IOperatorTaskSlice, imageId: TId) => {
  const image = state.image.entities[imageId];
  if (!image) {
    console.error('Изображение не найдено');
    return [];
  }

  let doc: INormalizedComplexDocument | undefined;

  if (image.docType === DocSection.Petitions && state.petition) {
    doc = state.petition;
  } else if (image.docType === DocSection.Awards) {
    doc = state.award.entities[image.docId];
  }

  if (!doc) {
    console.error('Документ не найден!');
    return [];
  }

  const imgIndex = doc.images.findIndex(id => id === image.id);
  if (imgIndex < 0) return [];

  if (image.docType === DocSection.Awards && imgIndex < 4) {
    const images = [];
    for (let i = 0; i < 4; i++) {
      if (i !== imgIndex) images.push(doc.images[i]);
    }

    return images;
  }

  const prevImage = doc.images[imgIndex - 1];
  const nextImage = doc.images[imgIndex + 1];

  if (imgIndex % 2 === 0 && nextImage) return [nextImage];
  if (imgIndex % 2 === 1 && prevImage) return [prevImage];
  return [];
};

export const getActiveImageData = (state: IOperatorTaskSlice) => {
  const imageId = state.activeSheet.currentImage;
  if (!imageId) {
    console.error('Не выбрано активное изображение!');
    return { imageId: null, image: null };
  }
  const image = state.image.entities[imageId];
  if (!image) {
    console.error('Не найдено изображение с указанным id');
    return { imageId, image: null };
  }

  const document =
    image.docType === DocSection.Petitions ? state.petition : state.award.entities[image.docId];

  const imgIndex = document?.images.findIndex(id => id === imageId) ?? -1;

  return { imageId, image, index: imgIndex };
};

export const makeImageStackAction = (
  state: IOperatorTaskSlice,
  image: INormalizedImage,
  type: EditorActionType,
  extraImages?: string[],
) => {
  const action: IStackAction = {
    id: nanoid(),
    type,
    payload: {
      images: [image.id],
    },
  };
  if (extraImages) {
    action.payload.images!.push(...extraImages);
  }
  if (image.docType === DocSection.Petitions) {
    action.payload.petitions = [image.docId];
  } else {
    action.payload.awards = [image.docId];
  }
  actionsAdapter.addOne(state.action, action);

  return action;
};

export const makeMultipleImageStackAction = (
  state: IOperatorTaskSlice,
  images: TId[],
  section: DocSection,
  docId: TId,
  type: EditorActionType,
) => {
  const action: IStackAction = {
    id: nanoid(),
    type,
    payload: {
      images,
    },
  };
  action.payload[section] = [docId];
  actionsAdapter.addOne(state.action, action);

  return action;
};

export const addDocumentAction = (
  state: IOperatorTaskSlice,
  action: IAction,
  section: DocSection,
  docId: TId,
) => {
  if (section === DocSection.Petitions && state.petition) {
    state.petition.actions.push(action);
    state.petition.touched = true;
  } else if (section === DocSection.Awards) {
    const award = state.award.entities[docId];
    if (!award) return;
    awardsAdapter.updateOne(state.award, {
      id: docId,
      changes: { actions: [...award.actions, action], touched: true },
    });
  }
};

export const getLinkedImagesRotateActions = (
  image: INormalizedImage,
  index: number,
  linkedImages: INormalizedImage[],
  action: IRotateAction,
) => {
  const { docType } = image;

  const oppositeRotationAction = { ...action, payload: -action.payload };
  if (index < 4 && docType === DocSection.Awards) {
    switch (true) {
      case index === 0: {
        return [
          linkedImages[0] ? [...linkedImages[0].actions, oppositeRotationAction] : null,
          linkedImages[1] ? [...linkedImages[1].actions, oppositeRotationAction] : null,
          linkedImages[2] ? [...linkedImages[2].actions, action] : null,
        ];
      }
      case index === 1 || index === 2: {
        return [
          linkedImages[0] ? [...linkedImages[0].actions, oppositeRotationAction] : null,
          linkedImages[1] ? [...linkedImages[1].actions, action] : null,
          linkedImages[2] ? [...linkedImages[2].actions, oppositeRotationAction] : null,
        ];
      }
      case index === 3: {
        return [
          linkedImages[0] ? [...linkedImages[0].actions, action] : null,
          linkedImages[1] ? [...linkedImages[1].actions, oppositeRotationAction] : null,
          linkedImages[2] ? [...linkedImages[2].actions, oppositeRotationAction] : null,
        ];
      }
    }
  } else {
    return linkedImages.map(img => [...img.actions, oppositeRotationAction]);
  }
};

const removeStackAction = (state: IOperatorTaskSlice, action: IStackAction) => {
  action.payload.images?.forEach(imgId => {
    state.activeSheet.currentImage = action.payload.images![0];
    if (action.payload.petitions?.length) {
      state.activeDocument = {
        id: action.payload.petitions[0],
        barcode: state.petition?.barcode ?? '',
        docType: DocSection.Petitions,
      };
    }
    if (action.payload.awards?.length) {
      state.activeDocument = {
        id: action.payload.awards[0],
        barcode: state.award.entities[action.payload.awards[0]]?.barcode ?? '',
        docType: DocSection.Awards,
      };
    }

    const image = state.image.entities[imgId];
    const imgAction = image?.actions.find(a => a.id === action.id);
    if (!image || !imgAction) return;
    const actions = image.actions.filter(a => a.id !== action.id);
    const changes: Partial<INormalizedImage> = { actions, touched: !!actions.length };

    switch (action.type) {
      case EditorActionType.DeleteImage:
        changes.deleted = !image.deleted;
        break;
      case EditorActionType.ImportantImage:
        changes.important = !image.important;
        break;
      case EditorActionType.MakeImageRecognized:
        changes.recognized = !image.recognized;
        changes.needRecognize = !image.needRecognize;
        break;
      case EditorActionType.RecognizeSelected:
        changes.recognized = imgAction.payload.recognized;
        changes.needRecognize = imgAction.payload.needRecognize;
        break;
      default:
        break;
    }

    changes.mask = getImageMask({ ...image, ...changes });
    imagesAdapter.updateOne(state.image, {
      id: imgId,
      changes,
    });
  });
  action.payload.awards?.forEach(awardId => {
    const award = state.award.entities[awardId];
    if (!award) return;
    const actions = award.actions.filter(a => a.id !== action.id);
    awardsAdapter.updateOne(state.award, {
      id: awardId,
      changes: { actions, touched: !!actions.length },
    });
  });
  if (action.payload.petitions?.length && state.petition) {
    const actions = state.petition.actions.filter(a => a.id !== action.id);
    state.petition.actions = actions;
    state.petition.touched = !!actions.length;
  }
};

export const actionRewindReducer = (state: IOperatorTaskSlice, action: IAction) => {
  switch (action.type) {
    case EditorActionType.Rotate: {
      removeStackAction(state, action as IStackAction);
      break;
    }
    case EditorActionType.MakeImageRecognized: {
      removeStackAction(state, action as IStackAction);
      break;
    }
    case EditorActionType.ImportantImage: {
      removeStackAction(state, action as IStackAction);
      break;
    }
    case EditorActionType.DeleteImage: {
      removeStackAction(state, action as IStackAction);
      break;
    }
    case EditorActionType.DeleteAward: {
      removeStackAction(state, action as IStackAction);
      break;
    }
    case EditorActionType.SplitAward: {
      const act = action as IStackAction;
      if (!(act.payload.awards?.length === 2)) {
        toast.error('Что-то плошло не так!');
        return;
      }
      const awardToRestore = state.award.entities[action.payload.awards[0]];
      const awardToDelete = state.award.entities[action.payload.awards[1]];

      if (awardToDelete) {
        awardsAdapter.removeOne(state.award, awardToDelete.id);
      }

      if (awardToRestore) {
        const splitAction = awardToRestore?.actions.find(
          a => a.id === action.id,
        ) as ISplitAwardAction;
        if (!splitAction) {
          toast.error('Что-то плошло не так!');
          return;
        }
        const newActions = awardToRestore.actions.filter(a => a.id !== splitAction.id);
        const newImages = [...awardToRestore.images, ...splitAction.payload];
        newImages.forEach(imgId => {
          const img = state.image.entities[imgId];
          if (!img) return;
          imagesAdapter.updateOne(state.image, {
            id: imgId,
            changes: {
              docId: awardToRestore.id,
            },
          });
        });
        awardsAdapter.updateOne(state.award, {
          id: awardToRestore.id,
          changes: {
            actions: newActions,
            touched: !!newActions.length,
            images: newImages,
          },
        });
      }
      break;
    }
    case EditorActionType.CreateAward: {
      const act = action as IStackAction;
      if (!act.payload.awards?.[0]) {
        toast.error('Что-то пошло не так!');
        return;
      }
      awardsAdapter.removeOne(state.award, act.payload.awards[0]);
      break;
    }
    case EditorActionType.InsertImage: {
      const { data, insertedImages, section } = (action as IInsertImageAction).payload;
      if (section === DocSection.Petitions) {
        state.petition = data as INormalizedComplexDocument;
      } else if (section === DocSection.Awards) {
        awardsAdapter.setOne(state.award, data as INormalizedComplexDocument);
      }
      data.images.forEach(img => {
        const image = state.image.entities[img];
        if (!image) return;
        const newActions = image.actions.filter(a => a.id !== action.id);
        image.actions = newActions;
        image.touched = !!newActions.length;
      });
      insertedImagesAdapter.setAll(state.insertedImage, insertedImages);
      break;
    }
    case EditorActionType.ReplaceImage: {
      const a = action as IReplaceImageAction;
      const { image, index, insertedImages } = a.payload;
      const { docType, docId } = image;

      const doc = docType === DocSection.Petitions ? state.petition : state.award.entities[docId];

      if (!doc) {
        toast.error('Что-то пошло не так!');
        break;
      }

      insertedImagesAdapter.setAll(state.insertedImage, insertedImages);
      doc.actions = doc.actions.filter(a => a.id !== action.id);
      doc.images[index] = image.id;
      doc.touched = !!doc.actions.length;
      break;
    }
    case EditorActionType.MoveImage: {
      const a = action as IMoveImageAction;

      const img = state.image.entities[a.payload[0]];

      if (!img) {
        toast.error('Что-то пошло не так!');
        break;
      }

      const doc =
        img.docType === DocSection.Petitions ? state.petition : state.award.entities[img.docId];

      if (!doc) {
        toast.error('Что-то пошло не так!');
        break;
      }

      const docActions = doc.actions.filter(act => act.id !== a.id);
      doc.actions = docActions;
      doc.touched = !!docActions.length;
      doc.images = a.payload;

      doc.images.forEach(img => {
        const image = state.image.entities[img];
        if (!image) return;
        image.actions = image.actions.filter(act => act.id !== action.id);
        image.touched = image.actions.length > 0;
      });
      break;
    }
    case EditorActionType.SwitchImages: {
      const a = action as ISwitchImagesAction;
      const { payload } = a;
      const image = state.image.entities[payload[0]];
      if (!image) {
        toast.error('Что-то пошло не так!');
        break;
      }

      const doc =
        image.docType === DocSection.Petitions ? state.petition : state.award.entities[image.docId];

      if (!doc) {
        toast.error('Что-то пошло не так!');
        break;
      }

      doc.actions = doc.actions.filter(act => act.id !== a.id);
      doc.touched = !!doc.actions.length;
      doc.images = payload;

      doc.images.forEach(img => {
        const image = state.image.entities[img];
        if (!image) return;
        image.actions = image.actions.filter(act => act.id !== a.id);
        image.touched = image.actions.length > 0;
      });
      break;
    }
    case EditorActionType.RecognizeSelected: {
      removeStackAction(state, action as IStackAction);
      break;
    }
  }
};

export const getImageVisibility = (img: INormalizedImage, filters: IFilter): boolean => {
  const { important, notImportant, recognized, notRecognized } = filters;

  let visible = true;

  if (important && notImportant && recognized && notRecognized) visible = true;
  if (important && notImportant && recognized && !notRecognized) visible = img.recognized;
  if (important && notImportant && !recognized && notRecognized) visible = !img.recognized;
  if (important && !notImportant && recognized && notRecognized) visible = img.important;
  if (!important && notImportant && recognized && notRecognized) visible = !img.important;
  if (important && !notImportant && recognized && !notRecognized)
    visible = img.important && img.recognized;
  if (!important && notImportant && recognized && !notRecognized)
    visible = !img.important && img.recognized;
  if (important && !notImportant && !recognized && notRecognized)
    visible = img.important && !img.recognized;
  if (!important && notImportant && !recognized && notRecognized)
    visible = !img.important && !img.recognized;
  if (important && !notImportant && !recognized && !notRecognized) visible = img.important;
  if (!important && notImportant && !recognized && !notRecognized) visible = !img.important;
  if (!important && !notImportant && recognized && !notRecognized) visible = img.recognized;
  if (!important && !notImportant && !recognized && notRecognized) visible = !img.recognized;
  if (!important && !notImportant && !recognized && !notRecognized) visible = false;

  return visible;
};
