import { Dispatch, SetStateAction, useCallback } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { useGetDivisionsListQuery } from '../../../../redux/api/divisions';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import Select from '../../../../components/ui/Select';
import { IDivision } from '../../../../types/IDivision';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const PrintingReportFilters = ({ fetchOptionsSetter }: Props) => {
  const { data: divisions, isLoading: divisionsLoading } = useGetDivisionsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        page: 0,
        dateFromTs: startDate ?? undefined,
        dateToTs: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  return (
    <>
      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
      <Select
        isSearchable
        className="mr-4 w-60"
        placeholder="Управление"
        options={divisions}
        getOptionLabel={opt => (opt as IDivision).name}
        getOptionValue={opt => (opt as IDivision).id}
        isClearable
        onChange={division => {
          console.log(division);

          fetchOptionsSetter(prev => ({
            ...prev,
            divisionId: division ? (division as IDivision).id : undefined,
          }));
        }}
        isLoading={divisionsLoading}
      />
    </>
  );
};
export default PrintingReportFilters;
