import { Dispatch, SetStateAction, useCallback } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import { useGetUsersListQuery } from '../../../../redux/api/users';
import { IDateRange } from '../../../../types/IDateRange';
import DateRangeInput from '../../../../components/ui/DateRangeInput';
import Select from '../../../../components/ui/Select';
import { getSortedUsers } from '../../../../utils/select';
import { ISelectOption } from '../../../../types/ISelectOption';
import { useSearchParams } from 'react-router-dom';

interface Props {
  fetchOptionsSetter: Dispatch<SetStateAction<IStatOptions>>;
}

const UsersReportFilters = ({ fetchOptionsSetter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: users, isLoading: usersLoading } = useGetUsersListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const dateChangeHandler = useCallback(
    (dates: IDateRange) => {
      const { start, end } = dates;

      const startDate = start?.toISO();
      const endDate = end?.toISO();

      fetchOptionsSetter(prev => ({
        ...prev,
        page: 0,
        dateFromTs: startDate ?? undefined,
        dateToTs: endDate ?? undefined,
      }));
    },
    [fetchOptionsSetter],
  );

  const userSelectHandler = useCallback(
    (option: unknown) => {
      if (option) {
        searchParams.set('userId', (option as ISelectOption).value);
      } else {
        searchParams.delete('userId');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <>
      <DateRangeInput wrapped className="mr-4" onChange={dateChangeHandler} />
      <Select
        isSearchable
        className="mr-4 w-30"
        placeholder="Оператор"
        options={getSortedUsers(users, 'id')}
        isClearable
        onChange={userSelectHandler}
        isLoading={usersLoading}
      />
    </>
  );
};
export default UsersReportFilters;
