import { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { imageEditorActions, imageEditorSelectors } from '../../redux/slices/imageEditor';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { DocSection } from '../../types/DocSection';
import { ISlide } from '../../types/ISlide';
import { EditorActionType } from '../../types/EditorAction';
import { getImageVisibility } from '../../redux/slices/operatorTask/utils';
import DraggableView from '../../components/common/DraggableView';
import Slider from '../../components/common/Slider';
import { PAGE_SIDES_RATIO, SLIDER_WIDTH, SLIDE_PREVIEW_SIZE } from '../../const';

import styles from './WorkZone.module.scss';
import { layoutSelectors } from '../../redux/slices/layout';

interface Props {
  readOnly: boolean;
  className?: string;
}

const EditZone = ({ className, readOnly }: Props) => {
  const dispatch = useAppDispatch();
  const activeImage = useAppSelector(operatorTaskSelectors.selectActiveImage);
  const image = useAppSelector(operatorTaskSelectors.selectActiveImage);
  const imageIdealWidth = useAppSelector(layoutSelectors.selectPageWidth);
  const imageWidth = useAppSelector(imageEditorSelectors.selectImageWidth);
  const rotateDeg = useAppSelector(operatorTaskSelectors.selectRotateDeg);
  const isAnimated = useAppSelector(imageEditorSelectors.selectImageAnimationState);
  const isVisible = useAppSelector(operatorTaskSelectors.selectImageVisibility);
  const containerRef = useRef<HTMLDivElement>(null);
  const keepSize = useAppSelector(imageEditorSelectors.selectKeepSizeState);

  const containerWidth = useMemo(() => {
    return containerRef.current
      ? containerRef.current.clientWidth - SLIDER_WIDTH - 30
      : imageIdealWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIdealWidth, containerRef.current]);

  const document = useAppSelector(operatorTaskSelectors.selectActiveDocument);
  const images = document
    ? useAppSelector(state =>
        operatorTaskSelectors.selectImagesByIds(state, document?.images ?? []),
      )
    : useAppSelector(operatorTaskSelectors.selectAllActualImages);
  const selectedImages = useAppSelector(operatorTaskSelectors.selectImageSelection);
  const docInfo = useAppSelector(operatorTaskSelectors.selectActiveDocumentInfo);
  const filter = useAppSelector(operatorTaskSelectors.selectFilter);

  useEffect(() => {
    const isLandscape = Math.abs((rotateDeg / 90) % 2) === 1;

    if (!keepSize) {
      dispatch(
        imageEditorActions.setImageWidth(
          isLandscape ? containerWidth / PAGE_SIDES_RATIO : imageIdealWidth,
        ),
      );
    }
  }, [activeImage, containerWidth, dispatch, imageIdealWidth, rotateDeg, keepSize]);

  const slideClickHandler = useCallback(
    (image: ISlide) => {
      dispatch(imageEditorActions.turnOffImageAnimation());
      dispatch(operatorTaskActions.setActiveImage(image.id));
      setTimeout(() => {
        dispatch(imageEditorActions.turnOnImageAnimation());
      }, 300);
    },
    [dispatch],
  );

  const slideSelectHandler = useCallback(
    (image: ISlide) => {
      dispatch(operatorTaskActions.changeSelectedImages(image.id));
    },
    [dispatch],
  );

  const a3start = images[0]?.docType === DocSection.Awards && images[0]?.group !== null;

  const slides: ISlide[] = useMemo(() => {
    const items: ISlide[] = images.map(img => {
      const rotateDeg =
        img.rotation +
        img.actions
          .filter(a => a.type === EditorActionType.Rotate)
          .reduce((acc, a) => acc + a.payload, 0);
      return {
        id: img.id,
        src: img.filename + `/${SLIDE_PREVIEW_SIZE}/${SLIDE_PREVIEW_SIZE}`,
        rotateDeg,
        mod: img.mask,
        touched: 'touched' in img && !!img.touched,
        hidden: !getImageVisibility(img, filter),
      };
    });
    return items;
  }, [filter, images]);

  const filteredSlides = useMemo(() => {
    return slides.filter((slide, i) => {
      if (a3start && i < 4) {
        return true;
      } else if (i % 2 === 0 && slide.hidden && slides[i + 1] && slides[i + 1].hidden) {
        return false;
      } else if (i % 2 === 1 && slide.hidden && slides[i - 1] && slides[i - 1].hidden) {
        return false;
      } else if (i % 2 === 0 && slide.hidden && !slides[i + 1]) {
        return false;
      } else {
        return true;
      }
    });
  }, [a3start, slides]);

  const count = useMemo(() => {
    return filteredSlides.filter(i => !i.hidden).length;
  }, [filteredSlides]);

  const importantCount = useMemo(() => {
    return images.filter(i => i.important && getImageVisibility(i, filter)).length;
  }, [filter, images]);

  return (
    <div className={classNames(styles.root, styles.left, className)} ref={containerRef}>
      <Slider
        headerText={`Всего: ${count}`}
        secondaryText={`зн: ${importantCount} / незн: ${count - importantCount}`}
        docId={docInfo?.id}
        items={filteredSlides}
        selection={selectedImages}
        activeId={activeImage?.id}
        onSlideClick={slideClickHandler}
        onSlideSelect={slideSelectHandler}
        a3start={a3start}
        className={styles.listSlider}
        readOnly={readOnly}
        keyboard={true}
      />
      <DraggableView
        src={image ? `${image.filename}.png` : undefined}
        className={styles.view}
        imageWidth={imageWidth}
        imageFormat={image?.format}
        isAnimated={isAnimated}
        isVisible={isVisible}
        rotateDeg={rotateDeg}
        scrollBy={{ left: -120 }}
      />
    </div>
  );
};

export default EditZone;
