import GridActions from '../../../../components/common/GridActions';
import Button from '../../../../components/ui/Button';
import { BASE_URL } from '../../../../const';
import { IStatOptions, STAT_DATA_KEYS } from '../../../../redux/api/statistic';
import { getSearchParams } from '../../../../utils/searchParams';

interface Props {
  fetchOptions: IStatOptions;
}

const DepartmentsReportActions = ({ fetchOptions }: Props) => {
  const params = getSearchParams(fetchOptions, STAT_DATA_KEYS);

  return (
    <GridActions>
      <Button link={`${BASE_URL}api/statistics/complexes/totals/export${params}`} download>
        Экспорт
      </Button>
    </GridActions>
  );
};
export default DepartmentsReportActions;
