import { useState } from 'react';
import GridPage from '../../gridPage/GridPage';
import DepartmentsReportActions from './DepartmentsReportActions';
import DepartmentsReportFilters from './DepartmentsReportFilters';
import DepartmentsReportGrid from './DepartmentsReportGrid';
import { IStatOptions } from '../../../../redux/api/statistic';

const DepartmentsReport = () => {
  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  return (
    <GridPage
      title="Статистика по участку"
      className="pt-2 pb-7"
      panel={<DepartmentsReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={<DepartmentsReportGrid fetchOptions={fetchOptions} />}
      actions={<DepartmentsReportActions fetchOptions={fetchOptions} />}
    />
  );
};
export default DepartmentsReport;
