import { INormalizedPrintDocument } from '../../../types/IPrintDocument';
import { IPage } from '../../../types/IPage';
import { IPageImage } from '../../../types/IPageImage';
import { IPrintInfo } from '../../../types/IPrintInfo';
import { TId } from '../../../types/TId';
import { IPrintJob } from './../../../types/IPrintJob';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const pagesAdapter = createEntityAdapter<IPage>();
export const documentsAdapter = createEntityAdapter<INormalizedPrintDocument>();

interface IPrintJobSlice {
  info: IPrintInfo | null;
  document: ReturnType<typeof documentsAdapter.getInitialState>;
  page: ReturnType<typeof pagesAdapter.getInitialState>;
  selectedDocuments: string[];
  selectedPages: string[];
  activeDocument: string | null;
  activeImage: IPageImage | null;
}

const initialState: IPrintJobSlice = {
  info: null,
  document: documentsAdapter.getInitialState(),
  page: pagesAdapter.getInitialState(),
  selectedDocuments: [],
  selectedPages: [],
  activeDocument: null,
  activeImage: null,
};

const printJobSlice = createSlice({
  name: 'printJob',
  initialState,
  reducers: {
    setJob: (state, { payload }: PayloadAction<IPrintJob | null>) => {
      if (payload) {
        const { documents, ...info } = payload;
        state.info = info;
        const pages: IPage[] = [];
        const docs: INormalizedPrintDocument[] = [];
        documents.forEach(doc => {
          pages.push(...doc.pages);
          docs.push({ ...doc, pages: doc.pages.map(p => p.id) });
        });
        documentsAdapter.setAll(state.document, docs);
        pagesAdapter.setAll(state.page, pages);
        const firstPage = state.page.entities[state.page.ids[0]];
        state.activeImage = firstPage
          ? {
              id: firstPage.id + '-1',
              pageId: firstPage.id,
              src: firstPage.file1,
              format: firstPage.format,
            }
          : null;
      } else {
        return initialState;
      }
    },
    setDocument: (state, action: PayloadAction<TId | null>) => {
      state.activeDocument = action.payload;
      state.selectedPages = [];
    },
    changeSelectedDocuments: (state, action: PayloadAction<TId>) => {
      const selectedDoc = state.selectedDocuments.find(doc => doc === action.payload);
      if (selectedDoc) {
        state.selectedDocuments = state.selectedDocuments.filter(doc => doc !== action.payload);
      } else {
        state.selectedDocuments.push(action.payload);
      }
    },
    setImage: (state, action: PayloadAction<IPageImage | null>) => {
      state.activeImage = action.payload;
    },
    changeSelectedPages: (state, action: PayloadAction<TId>) => {
      const selectedPage = state.selectedPages.find(p => p === action.payload);
      if (selectedPage) {
        state.selectedPages = state.selectedPages.filter(p => p !== action.payload);
      } else {
        state.selectedPages.push(action.payload);
      }
    },
  },
});

export const { setJob, setDocument, changeSelectedDocuments, setImage, changeSelectedPages } =
  printJobSlice.actions;

export default printJobSlice.reducer;
