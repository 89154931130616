import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Row, createColumnHelper } from '@tanstack/react-table';

import Tag from '../../../components/ui/Tag';
import Card from '../../../components/ui/Card';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { makeDateRangeFilterFunction } from '../../../utils/filter';
import { PAGE_SIDES_RATIO, POLLING_INTERVAL_S } from '../../../const';
import {
  ComplexPriority,
  ComplexPriorityEnum,
  ComplexState,
  ComplexStateEnum,
  ComplexTypeEnum,
  IComplexInfo,
  READY_STATES,
} from '../../../types/IComplexInfo';
import { selectionActions, selectionSelectors } from '../../../redux/slices/selection';

import styles from './Grid.module.scss';
import { useGetComplexesListQuery } from '../../../redux/api/complexes';
import DataGrid from '../../../components/grid/DataGrid';
import Spinner from '../../../components/ui/Spinner';
import { userSelectors } from '../../../redux/slices/user';
import { QueryKeys } from '../../../data/QueryKeys';
import { OPERATOR_COMPLEXES } from './filters';
import { getReadableDateTime } from '../../../utils/date';
import OperatorActions from './OperatorActions';
import Button from '../../../components/ui/Button';
import { MdOutlineHideImage, MdOutlineImage } from 'react-icons/md';
import { layoutActions, layoutSelectors } from '../../../redux/slices/layout';

type TRow = IComplexInfo & { errorSign: string };

export interface IProcessingParams {
  needsCount: boolean;
  needsBarcode: boolean;
  otherOperator: boolean;
  disabled: boolean;
  initialCount: number;
  barcodeChangable: boolean;
  type: string;
  priorityRiseable: boolean;
  readyForAssembly: boolean;
  isDuplicate: boolean;
}

const columnHelper = createColumnHelper<TRow>();

const columns = [
  columnHelper.display({
    id: 'index',
    header: '#',
    cell: ({ row }) => row.index + 1,
    size: 50,
  }),
  columnHelper.accessor('errorSign', {
    header: '',
    cell: info => <span className={styles.error}>{info.getValue()}</span>,
    enableSorting: false,
    enableGlobalFilter: false,
    size: 20,
  }),
  columnHelper.accessor('name', {
    header: 'Имя документа',
    cell: info => <span className={styles.title}>{info.getValue()}</span>,
    enableSorting: true,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('type', {
    header: 'Тип документа',
    cell: info => {
      const type = info.getValue();
      let text = ComplexTypeEnum.getDisplayName(info.getValue());
      if (type === 'awardbigpart') {
        // const partNumber = info.row.original.barcode.split('_').pop();
        const partNumber = info.row.original.partNumber;
        text = `${partNumber ?? '?'} часть БН`;
      }
      return (
        <Tag variant="status" style={{ minWidth: '200px', margin: '0 auto' }}>
          {text}
        </Tag>
      );
    },
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('barcode', {
    header: 'Штрихкод (QR код)',
    cell: info => info.getValue(),
    size: 170,
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('dateAddTs', {
    header: 'Дата и время',
    cell: info => getReadableDateTime(info.getValue()),
    size: 160,
    enableColumnFilter: true,
    filterFn: makeDateRangeFilterFunction<TRow>(),
  }),
  columnHelper.accessor('state', {
    header: 'Статус',
    cell: info => (
      <Tag
        variant={info.getValue().includes('error') ? 'low' : 'status'}
        style={{ minWidth: '200px', display: 'block', margin: '0 auto' }}
      >
        {ComplexStateEnum.getDisplayName(info.getValue())}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('pagesTotal', {
    header: 'Общее кол-во',
    cell: info => (
      <Tag style={{ display: 'block', minWidth: '62px', width: '62px', margin: '0 auto' }}>
        {info.renderValue()}
      </Tag>
    ),
    size: 150,
    enableSorting: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor('userShowName', {
    header: 'Оператор',
    cell: info => info.renderValue(),
    enableColumnFilter: true,
    enableGlobalFilter: false,
    filterFn: 'equals',
  }),
  columnHelper.accessor('priority', {
    header: 'Приоритет',
    cell: info =>
      info.getValue() ? (
        <Tag variant="status" style={{ minWidth: '123px', margin: '0 auto' }}>
          {ComplexPriorityEnum.getDisplayName(info.getValue()!)}
        </Tag>
      ) : null,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    sortingFn: 'text',
  }),
];

const addErrorSign = (complex: IComplexInfo, index: number, data: IComplexInfo[]): TRow => {
  let errorSign = '';
  if (complex.state === ComplexState.ReadyToWorkNoCode) errorSign = '*';
  else if (complex.state === ComplexState.ReadyToWorkDuplicateCode) errorSign = '**';
  else if (complex.state.includes('error') || complex.state.includes('fail')) errorSign = '!';
  if (data.some((item, i) => item.name && item.name === complex.name && i !== index)) {
    errorSign = '||' + errorSign;
  }
  return { ...complex, errorSign };
};

const OperatorGrid = () => {
  const dispatch = useAppDispatch();
  const [listWidth, setListWidth] = useState(600);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [complexName, setComplexName] = useState<string | null>();
  const previewVisible = useAppSelector(layoutSelectors.selectPreviewState);

  const [processingParams, setProcessingParams] = useState<IProcessingParams>({
    needsCount: true,
    needsBarcode: false,
    otherOperator: false,
    disabled: false,
    initialCount: 1,
    barcodeChangable: true,
    type: '',
    priorityRiseable: false,
    readyForAssembly: false,
    isDuplicate: false,
  });

  const { data, isFetching: listLoading } = useGetComplexesListQuery(
    { states: OPERATOR_COMPLEXES },
    {
      pollingInterval: POLLING_INTERVAL_S,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const gridData = useMemo(() => {
    return data?.results.map(addErrorSign);
  }, [data]);

  const previewSrc = useAppSelector(selectionSelectors.selectSelectedPreview);
  const userData = useAppSelector(userSelectors.selectUserData);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setListWidth(Math.floor((ref.current.clientHeight - 60) / PAGE_SIDES_RATIO));
    }
    return () => {
      dispatch(selectionActions.setSelectedPreview(null));
    };
  }, [dispatch]);

  const rowClickHandler = useCallback(
    (row: Row<TRow>) => {
      if (previewSrc !== row.original.firstPageFilename) {
        setPreviewLoaded(false);
      }
      setComplexName(row.original.name ?? row.original.barcode);
      setProcessingParams(prev => ({
        ...prev,
        needsCount: row.original.awardsFactCount === null,
        needsBarcode:
          row.original.state === ComplexState.ReadyToWorkDuplicateCode ||
          row.original.state === ComplexState.ReadyToWorkNoCode,
        disabled:
          !READY_STATES.includes(row.original.state) ||
          (row.original.state === ComplexState.InWork && row.original.userId !== userData?.id),
        otherOperator:
          READY_STATES.includes(row.original.state) &&
          row.original.userId > 0 &&
          row.original.userId !== userData?.id,
        initialCount: row.original.awardsFactCount >= 0 ? row.original.awardsFactCount : 1,
        barcodeChangable: READY_STATES.includes(row.original.state),
        type: row.original.type,
        priorityRiseable: row.original.priority === ComplexPriority.Default,
        readyForAssembly: row.original.state === ComplexState.PartsReady,
        isDuplicate: row.original.state === ComplexState.LoadingDuplicate,
      }));
      dispatch(selectionActions.setSelectedPreview(row.original.firstPageFilename));
    },
    [dispatch, previewSrc, userData?.id],
  );

  return (
    <div className={styles.listActions} ref={ref}>
      <DataGrid
        gridKey={QueryKeys.Operator}
        className={classNames(styles.grid, 'mr-4')}
        columns={columns}
        rows={gridData}
        isLoading={listLoading}
        onRowClick={rowClickHandler}
        rowIdSelector={row => row.id}
        columnSettings={true}
        hiddenFilterColumn="userId"
        dateFilterColumn="dateAddTs"
        highlighter={row => {
          if (row.state === 'in_work' && row.userId !== userData?.id)
            return { isNeeded: true, color: 'rgba(255, 0, 0, 0.2)' };
          else if (
            READY_STATES.includes(row.state) &&
            row.userId > 0 &&
            row.userId !== userData?.id
          )
            return { isNeeded: true, color: 'rgba(255, 255, 0, 0.35)' };
          return { isNeeded: false };
        }}
        extraSetting={
          <Button
            viewType="round"
            title={previewVisible ? 'Скрыть превью' : 'Показать превью'}
            onClick={() => dispatch(layoutActions.togglePreviewState(!previewVisible))}
          >
            {previewVisible ? <MdOutlineHideImage /> : <MdOutlineImage />}
          </Button>
        }
      />
      {previewSrc && previewVisible ? (
        <Card
          className={classNames('mr-4', styles.previewCard)}
          style={{
            minWidth: `${listWidth + 50}px`,
            minHeight: `${listWidth * PAGE_SIDES_RATIO + 50}px`,
          }}
        >
          {!previewLoaded && <Spinner block />}
          <img
            src={`${previewSrc}.png`}
            width={listWidth}
            alt="Превью первого листа"
            loading="lazy"
            onLoad={() => setPreviewLoaded(true)}
          />
        </Card>
      ) : null}
      <OperatorActions
        processingParams={processingParams}
        setProcessingParams={setProcessingParams}
        complexName={complexName}
        setComplexName={setComplexName}
      />
    </div>
  );
};

export default OperatorGrid;
