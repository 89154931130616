import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import {
  RiCharacterRecognitionLine,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiDeleteBin6Line,
  RiCheckboxMultipleLine,
  RiArrowGoBackFill,
  RiFileDownloadLine,
  RiFileUploadLine,
} from 'react-icons/ri';
import { TbLockSearch } from 'react-icons/tb';
import { RxValue, RxValueNone } from 'react-icons/rx';
import { GoDesktopDownload } from 'react-icons/go';
import { BsTextareaT } from 'react-icons/bs';
import { TbReplace } from 'react-icons/tb';
import { FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { FaBarcode } from 'react-icons/fa';
import { LiaExchangeAltSolid } from 'react-icons/lia';
import Button from '../../components/ui/Button';

import { TickIcon } from '../../components/ui/icons/Icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AssignBarcodeModal from './modals/AssignBarcodeModal';
import useModal from '../../hooks/useModal';
import FileUpload from './FileUpload';
import ImageFilter from './ImageFilter';
import { imageEditorActions, imageEditorSelectors } from '../../redux/slices/imageEditor';
import { operatorTaskActions, operatorTaskSelectors } from '../../redux/slices/operatorTask';
import { DocSection } from '../../types/DocSection';

import styles from './OperatorTaskToolbar.module.scss';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { IImage } from '../../types/IImage';
import { complexesApi, useFinalizeComplexMutation } from '../../redux/api/complexes';
import Spinner from '../../components/ui/Spinner';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from '../../data/QueryKeys';
import { OPERATOR_COMPLEXES } from '../main/grids/filters';

interface Props {
  readOnly: boolean;
}

const OperatorTaskToolbar = ({ readOnly }: Props) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageWidth = useAppSelector(imageEditorSelectors.selectImageWidth);
  const activeDocument = useAppSelector(operatorTaskSelectors.selectActiveDocument);
  const activeImage = useAppSelector(operatorTaskSelectors.selectActiveImage);
  const insertedImages = useAppSelector(operatorTaskSelectors.selectInsertedImages);
  const insertedSelection = useAppSelector(operatorTaskSelectors.selectInsertedSelection);
  const imageSelection = useAppSelector(operatorTaskSelectors.selectImageSelection);
  const complexName = useAppSelector(operatorTaskSelectors.selectComplexName);
  const keepSize = useAppSelector(imageEditorSelectors.selectKeepSizeState);

  const { modalOpenHandler, modalMarkup } = useModal(<AssignBarcodeModal />);

  const actions = useAppSelector(operatorTaskSelectors.actions.selectIds);
  const activeImageIndex = useAppSelector(operatorTaskSelectors.selectActiveImageIndex);
  const imagesCount = useAppSelector(operatorTaskSelectors.selectDocumentImageCount);

  const [finalizeComplex, { isLoading: finalizingComplex, isSuccess: finalizingSuccess }] =
    useFinalizeComplexMutation();

  useEffect(() => {
    if (finalizingSuccess) {
      toast.success(
        readOnly ? 'Задание отправлено на сборку!' : `Задание ${complexName} завершено!`,
      );
      navigate(`/${QueryKeys.Operator}`);
      dispatch(
        complexesApi.endpoints.getComplexesList.initiate(
          { states: OPERATOR_COMPLEXES },
          {
            forceRefetch: true,
            subscribe: false,
          },
        ),
      );
    }
  }, [complexName, dispatch, finalizingSuccess, navigate, readOnly]);

  const finishHandler = useCallback(() => {
    if (actions.length) return toast.error('Сохраните изменения перед завершением задания!');
    if (!taskId) return toast.error('Не удалось определить идентификатор задания!');
    finalizeComplex(taskId);
  }, [actions.length, finalizeComplex, taskId]);

  const { modalOpenHandler: finishConfirmOpenHandler, modalMarkup: finishConfirmModal } = useModal(
    <ConfirmationModal
      title={readOnly ? 'Сборка задания' : 'Завершение обработки'}
      text={
        readOnly
          ? 'Вы действительно хотите осуществит сборку задания?'
          : 'Вы действительно хотите завершить обработку?'
      }
      onConfirm={finishHandler}
    />,
  );

  const zoomInHandler = useCallback(() => {
    dispatch(imageEditorActions.setImageWidth(pageWidth + 50));
  }, [dispatch, pageWidth]);

  const zoomOutHandler = useCallback(() => {
    dispatch(imageEditorActions.setImageWidth(pageWidth - 50));
  }, [dispatch, pageWidth]);

  const toggleKeepSizeHandler = useCallback(() => {
    dispatch(imageEditorActions.toggleKeepSize());
  }, [dispatch]);

  const rotateLeftHandler = useCallback(() => {
    if (!activeImage) return;
    dispatch(operatorTaskActions.rotateImage(-90));
  }, [activeImage, dispatch]);

  const rotateRightHandler = useCallback(() => {
    if (!activeImage) return;
    dispatch(operatorTaskActions.rotateImage(90));
  }, [activeImage, dispatch]);

  const uploadHandler = useCallback(
    (data: IImage[]) => dispatch(operatorTaskActions.setInsertedImages(data)),
    [dispatch],
  );

  const assignBarcodeClickHandler = useCallback(() => {
    if (!activeDocument) {
      toast.error('Для продолжения выберите наградной лист!');
      return;
    }

    if (activeImage?.docType !== DocSection.Awards) {
      toast.error(
        'Выбран образ ходатайства, для продолжения выберите изображение, относящееся к наградному листу!',
      );
      return;
    }
    modalOpenHandler();
  }, [activeDocument, activeImage?.docType, modalOpenHandler]);

  const externalEditorHandler = useCallback(() => {
    window.open('tirazh://');
  }, []);

  return (
    <>
      {finalizingComplex && <Spinner />}
      <div className={classNames(styles.btnGroup, styles.editBtnGroup)}>
        <div className={styles.btnWrapper}>
          <ImageFilter />
          {!readOnly && (
            <>
              <Button
                icon={activeImage?.important ? <RxValueNone /> : <RxValue />}
                viewType="toolbar"
                title={activeImage?.important ? 'Сделать незначимым' : 'Сделать значимым'}
                onClick={() => dispatch(operatorTaskActions.toggleImportance())}
                disabled={!activeImage || activeImage.deleted}
              />
              <Button
                icon={<RiDeleteBin6Line />}
                viewType="toolbar"
                title="Удалить образы"
                onClick={() => dispatch(operatorTaskActions.deleteImage())}
                disabled={
                  !activeImage ||
                  activeImage.deleted ||
                  (imageSelection.length > 1 && !activeDocument)
                }
              />
              <Button
                icon={<RiCharacterRecognitionLine />}
                title="Распознать выделенное"
                viewType="toolbar"
                disabled={!imageSelection.length || !activeDocument}
                onClick={() => dispatch(operatorTaskActions.recognizeSelection(imageSelection))}
              />
              <Button
                icon={<BsTextareaT />}
                title="Назначить распознанной"
                viewType="toolbar"
                onClick={() => dispatch(operatorTaskActions.makeImageRecognized())}
                disabled={
                  (imageSelection.length && !activeDocument) ||
                  (!imageSelection.length && !activeImage)
                }
              />
              <Button
                icon={<RiInsertColumnLeft />}
                viewType="toolbar"
                title="Вставить образ до"
                disabled={!(activeImageIndex >= 0 && insertedImages.length)}
                onClick={() => {
                  dispatch(operatorTaskActions.insertImages(activeImageIndex));
                }}
              />
              <Button
                icon={<RiInsertColumnRight />}
                viewType="toolbar"
                title="Вставить образ после"
                disabled={!(activeImageIndex >= 0 && insertedImages.length)}
                onClick={() => {
                  dispatch(operatorTaskActions.insertImages(activeImageIndex + 1));
                }}
              />
              <Button
                icon={<RiFileUploadLine />}
                viewType="toolbar"
                title="Сдвинуть вверх"
                onClick={() => dispatch(operatorTaskActions.changeImageOrder(-1))}
                disabled={
                  !activeImage ||
                  (activeImage.docType === DocSection.Awards && activeImageIndex < 6) ||
                  (activeImage.docType === DocSection.Petitions && activeImageIndex < 2)
                }
              />
              <Button
                icon={<RiFileDownloadLine />}
                viewType="toolbar"
                title="Сдвинуть вниз"
                onClick={() => dispatch(operatorTaskActions.changeImageOrder(1))}
                disabled={
                  !activeImage ||
                  (activeImage.docType === DocSection.Awards && activeImageIndex < 4) ||
                  activeImageIndex >= imagesCount - 2
                }
              />
              <Button
                icon={<TbReplace />}
                viewType="toolbar"
                title="Поменять местами"
                disabled={imageSelection.length !== 2}
                onClick={() =>
                  dispatch(operatorTaskActions.switchImages(imageSelection as [string, string]))
                }
              />
            </>
          )}
        </div>
        <div className={classNames(!readOnly && 'ml-a', styles.btnWrapper)}>
          {!readOnly && (
            <>
              <Button
                icon={<FaBarcode />}
                viewType="toolbar"
                title="Назначить штрихкод"
                onClick={assignBarcodeClickHandler}
                disabled={!activeImage || activeImage.docType !== DocSection.Awards}
              />
              <Button
                icon={<FiRotateCcw />}
                viewType="toolbar"
                title="Повернуть влево"
                onClick={rotateLeftHandler}
              />
              <Button
                icon={<FiRotateCw />}
                viewType="toolbar"
                title="Повернуть вправо"
                onClick={rotateRightHandler}
              />
            </>
          )}
          <Button
            icon={<AiOutlineMinus />}
            viewType="toolbar"
            title="Уменьшить"
            onClick={zoomOutHandler}
          />
          <Button
            icon={<AiOutlinePlus />}
            viewType="toolbar"
            title="Увеличить"
            onClick={zoomInHandler}
          />
          <Button
            icon={<TbLockSearch />}
            viewType="toolbar"
            title="Общий зум"
            onClick={toggleKeepSizeHandler}
            selected={keepSize}
          />
          {!readOnly && (
            <>
              <Button
                className="mr-10"
                icon={<GoDesktopDownload />}
                viewType="toolbar"
                title="Открыть в редакторе"
                onClick={externalEditorHandler}
              />
            </>
          )}
        </div>
      </div>
      <div className={classNames(styles.btnGroup, styles.insertBtnGroup)}>
        {!readOnly && (
          <div className={classNames(styles.btnWrapper, 'ml-10')}>
            <FileUpload accept="image/tiff,.tif" onLoad={uploadHandler} />
            <Button
              icon={<RiCheckboxMultipleLine />}
              viewType="toolbar"
              title="Добавить"
              disabled={!(!activeImage && activeDocument && !!insertedImages.length)}
              onClick={() => {
                dispatch(operatorTaskActions.insertImages(0));
              }}
            />
            <Button
              icon={<LiaExchangeAltSolid />}
              viewType="toolbar"
              title="Заменить"
              disabled={!insertedImages.length}
              onClick={() => dispatch(operatorTaskActions.replaceImage(insertedSelection[0]))}
            />
            <Button
              icon={<RiDeleteBin6Line />}
              viewType="toolbar"
              title="Очистить"
              onClick={() => dispatch(operatorTaskActions.resetInserted())}
              disabled={!insertedImages.length}
            />
          </div>
        )}
        <div className={classNames(styles.commonActions, styles.btnWrapper)}>
          {!readOnly && (
            <Button
              icon={<RiArrowGoBackFill />}
              viewType="toolbar"
              variant="danger"
              title="Отменить последнее действие"
              onClick={() => dispatch(operatorTaskActions.chancelLastAction())}
              disabled={actions.length === 0}
            />
          )}
          <Button
            variant="success"
            icon={<TickIcon />}
            className={styles.finishButton}
            viewType="toolbarText"
            title={readOnly ? 'Собрать задание' : 'Завершить обработку'}
            disabled={actions.length > 0}
            onClick={finishConfirmOpenHandler}
          >
            {readOnly ? 'Собрать' : 'Завершить'}
          </Button>
        </div>
      </div>
      {modalMarkup}
      {finishConfirmModal}
    </>
  );
};

export default OperatorTaskToolbar;
