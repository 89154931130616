import { useState } from 'react';
import { IStatOptions } from '../../../../redux/api/statistic';
import GridPage from '../../gridPage/GridPage';
import PrintingReportFilters from './PrintingReportFilters';
import PrintingReportGrid from './PrintingReportGrid';
import PrintingReportActions from './PrintingReportActions';

const PrintingReport = () => {
  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  return (
    <GridPage
      title="Статистика по обратной печати"
      className="pt-2 pb-7"
      panel={<PrintingReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={<PrintingReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />}
      actions={<PrintingReportActions fetchOptions={fetchOptions} />}
    />
  );
};
export default PrintingReport;
