import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user/slice';
import layoutSlice from './slices/layout/slice';
import headerInfoSlice from './slices/headerInfo/slice';
import selectedRowsSlice from './slices/selection/slice';
import imageEditorSlice from './slices/imageEditor/slice';
import { api } from './api';
import { setupListeners } from '@reduxjs/toolkit/query';
import notificationSlice from './slices/notification/slice';
import operatorTaskSlice from './slices/operatorTask/slice';
import printJobSlice from './slices/printJob/slice';
import { rtkQueryErrorHandler } from '../middleware/rtkQueryErrorHandler';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    layout: layoutSlice,
    headerInfo: headerInfoSlice,
    notification: notificationSlice,
    imageEditor: imageEditorSlice,
    operatorTask: operatorTaskSlice,
    printJob: printJobSlice,
    selection: selectedRowsSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorHandler),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
