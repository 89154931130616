import { useState } from 'react';
import GridPage from '../../gridPage/GridPage';
import PetitionsReportFilters from './PetitionsReportFilters';
import PetitionsReportGrid from './PetitionsReportGrid';
import PetitionsReportActions from './PetitionsReportActions';
import { IStatOptions } from '../../../../redux/api/statistic';

const PetitionsReport = () => {
  const [fetchOptions, setFetchOptions] = useState<IStatOptions>({
    page: 0,
    perPage: 20,
  });

  return (
    <GridPage
      title="Статистика по ходатайствам"
      className="pt-2 pb-7"
      panel={<PetitionsReportFilters fetchOptionsSetter={setFetchOptions} />}
      grid={
        <PetitionsReportGrid fetchOptions={fetchOptions} fetchOptionsSetter={setFetchOptions} />
      }
      actions={<PetitionsReportActions fetchOptions={fetchOptions} />}
    />
  );
};

export default PetitionsReport;
